import React, { ReactElement } from 'react';
import type { NextPageWithLayout } from '@/pages/_app';
import GeneralLayout from '@/layouts/GeneralLayout';
import GeneralNestedLayout from '@/layouts/GeneralNestedLayout';
import HeroBanner from '@/components/banners/HeroBanner';
import Image from 'next/image';
const Page: NextPageWithLayout = () => {
  return <>
      <div className='page-not-found'>
        <div>
          <div className='page-not-found_image'>
            <Image src='/images/misc/404.png' alt='404' width={491} height={473} />
          </div>
          <div className='page-not-found_header'>
            <h2>Page not found</h2>
            <p className='page-not-found_text'>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
          </div>
        </div>
      </div>
    </>;
};
Page.getLayout = function getLayout(page: ReactElement) {
  return <GeneralLayout>
      <HeroBanner firstTitle='404' secondTitle='Page not found' imageUrl='/images/banners/home-banner.png' />
      <GeneralNestedLayout>
        {page}
      </GeneralNestedLayout>
    </GeneralLayout>;
};
export default Page;